.tab-item {
  flex: 1;
  text-align: center;
  padding: 12px 0;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  border: 1px solid #d1d1db;
  color: #8a8aa3;
  font-weight: normal;
  background-color: #fff;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

/* Remove the border-right: none rule */
/* .tab-item:not(:last-child) {
  border-right: none;
} */

.tab-item.active {
  color: var(--theme-primary-color);
  font-weight: 500;
  background-color: #fff;
  border-bottom: 2px solid var(--theme-primary-color);
  margin-bottom: -1px;
}

/* Remove the negative margin that was hiding borders */
/* .tab-item:not(:first-child) {
  margin-left: -1px;
} */

.tab-item:hover:not(.active) {
  background-color: #f8f8f8;
}

/* First and last tab specific styles */
.tab-item:first-child {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}

.tab-item:last-child {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}

/* Add icons styling */
.tab-item i {
  font-size: 16px;
}

.tab-item.active i {
  color: var(--theme-primary-color);
}

/* ... existing code ... */

.dark-theme {
  background-color: #1E1E29; /* Dark theme background color */
}

/* Ensure tab-item styles are nested under .dark-theme if needed */
.dark-theme .tab-item {
  background-color: #3a3a50; /* Dark background for tabs */
  color: #ccc; /* Light text color */
  border-color: #444; /* Dark border */
}

.dark-theme .tab-item.active {
  color: var(--theme-primary-color);
  background-color: #3A3A50; /* Slightly lighter dark background */
  border-bottom-color: var(--theme-primary-color);
}

.dark-theme .tab-item:hover:not(.active) {
  background-color: #3a3a50; /* Hover effect for dark theme */
}

/* ... existing code ... */
