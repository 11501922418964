.popup .p-menu .p-menuitem-link .p-menuitem-icon {
  color: none;
}

.popup .p-menuitem-icon {
  color: red !important;
}

.popup .p-menuitem-text {
  color: red !important;
}
