.sidebar .p-sidebar-mask {
  position: relative;
}
.sidebar .p-sidebar-mask .p-sidebar {
  width: 100%;
}

.p-sidebar {
  transition: none !important;
  box-shadow: none !important;
}

.p-sidebar-left {
  transform: translateX(0) !important;
}

.p-sidebar-right {
  transform: translateX(0) !important;
}

.p-sidebar-top {
  transform: translateY(0) !important;
}

.p-sidebar-bottom {
  transform: translateY(0) !important;
}

.p-sidebar-content {
  @apply overflow-x-hidden !important;
  @apply overflow-y-hidden !important;
}

.branding {
  display: block;
  height: max-content;
}

.sidebarList {
  height: 75vh;
  overflow-y: scroll;
  padding-bottom: 2rem;
  scrollbar-width: none;
  -ms-overflow-style: none;
  -webkit-overflow-scrolling: touch;
}
.sidebarList::-webkit-scrollbar {
  display: none;
}

.p-sidebar .p-sidebar-header {
  padding: 0.25rem;
}
